.requote-request {
  margin-left: -15px;

  .requote-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 285px;
    transition: width, left, right, 0.3s;

    &.sidebar-collapsed {
      padding-left: 95px;
    }
  }

  .requote-actions {
    background-color: rgba(204, 204, 204, 0.8) !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1008;
    padding-left: 285px;
    transition: width, left, right, 0.3s;

    .btn {
      letter-spacing: 1px !important;
    }

    &.sidebar-collapsed {
      padding-left: 95px;
    }
  }
}
