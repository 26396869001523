.notification-wrapper {
  position: absolute !important;
  top: 70px !important;
  right: 10px !important;
  z-index: 9999 !important;
}

.notification-wrapper .toast {
  min-width: 350px;
  background-color: #fff;
}

.notification-wrapper .toast-header {
  margin: 0 !important;
  padding: 10px 10px 10px 0 !important;
  background-color: #f8f9fa !important;
  display: flex;
  flex-direction: row;
}

.notification-wrapper .toast-header svg {
  flex: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
}

.notification-wrapper .error .toast-header svg {
  color: #dc3545;
}

.notification-wrapper .success .toast-header svg {
  color: #28a745;
}

.notification-wrapper .warning .toast-header svg {
  color: #ffc107;
}

.notification-wrapper .toast-header .message {
  font-weight: 600;
  flex: auto;
  display: flex;
  flex-direction: column;
  color: #000;
}

.notification-wrapper .error .toast-header .message small {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

.notification-wrapper .toast-body {
  max-height: 150px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  overflow-wrap: break-word !important;
  white-space: pre-line !important;
}
