.ins-score-header {
    background-color: #70639e !important;
    margin: -1rem -0.9rem 1.5rem -1rem;
    color: #fff;
}

.wdp-20 {
    width:40%;
}
.wdp-30 {
    width:60%;
}

.ins-score-footer {
    background-color: rgba(204, 204, 204, 0.8) !important;
    margin: 0rem -0.5rem 0rem -1rem;
    width: 101% !important;
    position:fixed;
    bottom:0;
}

.ins-score-main {
    width: 50%;
    margin: 0 auto;   
    margin-left: 39.5%;
}