.entities-list h2.entity-header {
  border-left: 5px solid #8a8ac3;
}

.entities-list .entity-header {
  .entity-title {
    font-size: 2rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 2px;
    font-weight: 500;
    line-height: 1.2;
  }

  .roles {
    font-size: 12px;
    top: -5px;
    left: 0;

    span.badge {
      font-size: 12px;
      border-radius: 0;

      &.badge-applicant {
        background-color: #df7656 !important;
        color: #fff !important;
      }
    }
  }

  .sgi-personal-information {
    text-transform: uppercase;
  }

  .btn-personal-info {
    margin-top: -7px;
  }
}
.driver-view {
  margin-bottom: 64px;

  // Helps DatePicker component to appear completely
  .accordion .card {
    overflow: unset !important;
  }

  .inline-editor {
    input {
      width: 100px !important;
    }
  }
}

.driver-view .card {
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 15px;
  position: relative;
}

.driver-view .card::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  border: 1px dashed #6e8898;
  position: absolute;
  top: 25px;
  left: 18px;
}

.driver-view .accordion:last-child .card::before {
  display: none;
}

.driver-view .card-header {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  position: relative !important;
  height: 45px !important;
  display: flex !important;
  align-items: baseline !important;
  cursor: pointer !important;

  .invalid-warning {
    color: #dc3545;
    font-size: 90%;
  }
}

.driver-view .card-header h4 {
  float: left;
  padding: 10px 30px 10px 60px !important;
  margin: 0 !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
  color: #1d3557 !important;
  border-radius: 0 !important;
  position: relative !important;
}

.driver-view .card-header .toggle-icon {
  width: 40px !important;
  line-height: 40px !important;
  border-radius: 3px !important;
  font-size: 17px !important;
  text-align: center !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2 !important;
  background: #f8f9fa !important;
  border: 1px solid #6e8898 !important;
  color: #000 !important;
  cursor: pointer;
}

.driver-view .card-header .toggle-icon.expanded,
.driver-view .card-header .toggle-icon:hover {
  transition: all 0.3s ease 0s !important;
  font-weight: 900 !important;
  background: #70639e !important;
  border: 1px solid #70639e !important;
  color: #fff !important;
}

.driver-view .card-body {
  padding: 10px 10px 10px 20px;
  margin-left: 40px;
  background: #fff;
  border-top: none;
  font-size: 15px;
  color: #6f6f6f;
  line-height: 28px;
  letter-spacing: 1px;
}

.list-view {
  margin: 0 0 10px 0 !important;
  position: relative;
}

.list-view .add-new-item {
  margin-bottom: 5px !important;
  position: absolute;
  top: -46px;
  right: 0px;
}

.list-view table {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.list-view tr td:last-child {
  width: 120px;
  text-align: right;
}

.list-view tr td.date-column {
  width: 130px;
}

.list-view tr td.date-column-lg {
  width: 190px;
}

.list-view tr td.description-column {
  width: 350px;
}

.list-view .add-new-item {
  display: flex;
  align-items: center;
  padding-top: 0.25em;
}

.no-data {
  text-align: left;
  padding-left: 10px;
}
