.vehicle-view {
    margin-bottom: 64px;

    // Helps DatePicker component to appear completely
    .accordion .card {
      overflow: unset !important;
    }
  
    .inline-editor {
      input {
        width: 100px !important;
      }
    }
  }

  .vehicle-view .card {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 15px;
    position: relative;
  }
  
  .vehicle-view .card::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    border: 1px dashed #6e8898;
    position: absolute;
    top: 25px;
    left: 18px;
  }
  
  .vehicle-view .accordion:last-child .card::before {
    display: none;
  }
  
  .vehicle-view .card-header {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    position: relative !important;
    height: 45px !important;
    display: flex !important;
    align-items: baseline !important;
    cursor: pointer !important;
  
    .invalid-warning {
      color: #dc3545;
      font-size: 90%;
    }
  }
  
  .vehicle-view .card-header h4 {
    float: left;
    padding: 10px 30px 10px 60px !important;
    margin: 0 !important;
    font-size: 18px !important;
    letter-spacing: 1px !important;
    color: #1d3557 !important;
    border-radius: 0 !important;
    position: relative !important;
  }
  
  .vehicle-view .card-header .toggle-icon {
    width: 40px !important;
    line-height: 40px !important;
    border-radius: 3px !important;
    font-size: 17px !important;
    text-align: center !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 2 !important;
    background: #f8f9fa !important;
    border: 1px solid #6e8898 !important;
    color: #000 !important;
    cursor: pointer;
  }
  
  .vehicle-view .card-header .toggle-icon.expanded,
  .vehicle-view .card-header .toggle-icon:hover {
    transition: all 0.3s ease 0s !important;
    font-weight: 900 !important;
    background: #70639e !important;
    border: 1px solid #70639e !important;
    color: #fff !important;
  }
  
  .vehicle-view .card-body {
    padding: 10px 10px 10px 20px;
    margin-left: 40px;
    background: #fff;
    border-top: none;
    font-size: 15px;
    color: #6f6f6f;
    line-height: 28px;
    letter-spacing: 1px;
  }
  