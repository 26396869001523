$primary: #4498a9;
$secondary: #818174;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  padding-top: 65px;
}

body #root {
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.loader-wrapper .loader-icon {
  width: 150px;
  height: 150px;
}

.btn-group.btn-group-toggle label.btn-primary {
  color: #70639e !important;
  background-color: #fff !important;
  border-color: #70639e !important;
}

.btn-group.btn-group-toggle label.btn-primary.active {
  color: #fff !important;
  background-color: #70639e !important;
  border-color: #70639e !important;
}

//remove arrows from number input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
