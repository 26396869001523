.licenses.list-view {
  .training-editor {
    display: flex;
    align-items: baseline;

    .react-datepicker-wrapper input {
      width: 150px !important;
    }
  }
}
