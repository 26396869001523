$sidebar-bg-color: #fff;
$sidebar-color: #1d3557;
$submenu-bg-color: #f8f9fa;
$submenu-bg-color-collapsed: #f8f9fa;
$highlight-color: #1d3557;
$sidebar-toggle-color: #70639e;
$active-menu-item-bg-color: #70639e;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.requote-sidebar-toggle-wrapper {
  z-index: 1010;
  left: 255px;
  cursor: pointer;
  transition: width, left, right, 0.3s;
  top: 92px;
  background-color: #fff;
  border-radius: 50%;

  .requote-sidebar-toggle {
    display: flex;
    color: darken($sidebar-toggle-color, 20%);
    &:hover {
      color: $sidebar-toggle-color;
    }
  }

  &.collapsed {
    left: 68px;

    .requote-sidebar-toggle {
      color: darken(#ccc, 20%);

      &:hover,
      &:visited,
      &:active {
        color: darken($sidebar-toggle-color, 20%);
      }
    }
  }
}

.requote-sidebar {
  margin-top: -1rem;
  height: unset;
  border-right: 2px solid $sidebar-toggle-color;
  top: 80px;
  bottom: 0;

  .pro-menu {
    padding-top: 0 !important;

    .pro-menu-item {
      &.active,
      &:not(.pro-sub-menu):hover {
        background-color: $active-menu-item-bg-color;
        color: #fff !important;

        a,
        svg {
          color: #fff !important;
        }
      }

      &.pro-sub-menu .pro-inner-list-item {
        padding: 0 !important;

        ul {
          padding: 0 !important;
        }

        .pro-inner-item {
          padding: 15px 10px 15px 75px;

          &:before {
            display: none;
          }
        }

        .pro-menu-item:hover {
          background-color: $active-menu-item-bg-color;
          a {
            color: #fff;
          }
        }
      }
    }
  }

  &.collapsed {
    border-right: 2px solid #ccc;

    .pro-sidebar-inner > .pro-sidebar-layout {
      overflow-y: hidden;
    }

    .pro-menu {
      .pro-menu-item {
        &:is(.pro-sub-menu) {
          &.active-child,
          &:hover {
            & > .pro-inner-item {
              background-color: $active-menu-item-bg-color;
              color: #fff !important;

              a,
              svg {
                color: #fff !important;
              }
            }
          }
        }

        &.pro-sub-menu .pro-inner-list-item {
          .popper-inner {
            padding-left: 0 !important;
          }

          .pro-inner-item {
            padding: 15px 10px 15px 15px !important;
          }
        }
      }
    }
  }
}
