header a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

header a.nav-link.active {
    font-weight: 600;
}

header .user-menu .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

header .user-menu .dropdown-menu {
    right: 0;
    left: auto;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
