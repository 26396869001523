.conviction-editor .modal-footer {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

/* .conviction-editor .scroll {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
} */

.conviction-editor .delete-supplement {
  cursor: pointer;
}

.conviction-editor .delete-supplement:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
