.quote-request-header {
  background-color: #70639e !important;
  // background-color: #4498a9 !important;
  border-left: 2px solid #fff;
  margin: -1rem -0.9rem 1.5rem -1rem;
  color: #fff;
  // color: #212529;

  .row {
    align-items: center !important;
  }

  h1 {
    font-size: 2rem;
  }
}
